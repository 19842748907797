<template>
  <section id="media-message">
    <div class="mb-0 media-message" v-if="botMessageInEdit.isSaved">
      <MediaPreview v-if="showPreview" :savedFile="savedFile" />
      <!-- <img
        :src="botMessageInEdit.type.fileUrl"
        class="img-message"
        alt="bot-image"
        v-if="botMessageInEdit.type.fileUrl"
      />-->
    </div>

    <div class="mb-0">
      <Message
        class="chat__input flex bg-white"
        :has-errors="hasError"
        @uploadedFile="showPreview = false"
        :read-only="readOnly"
      />
    </div>

    <!-- <form
      @submit.prevent="addMessage()"
      class="submit-button chat__input flex p-2 bg-white"
    >-->
    <div
      class="error-message height-fit-content my-3 text-center"
      v-if="errorMsg && errorMsg.length"
    >
      <p v-for="(errorObj, index) in errorMsg" :key="index">{{ errorObj }}</p>
    </div>

    <div class="mt-4">
      <BotMessageValidation :read-only="readOnly"/>
    </div>

    <div class="flex justify-center mt-4" v-if="!readOnly">
      <vs-button color="danger" type="flat" @click="onCancel">
        {{ lang.botMaker.botEditor.cancelMessage[languageSelected] }}
      </vs-button>

      <vs-button
        :disabled="buttonDisabled"
        class="bg-primary-gradient ml-4"
        type="filled"
        @click="addMessage()"
      >
        <span
          v-if="!buttonDisabled && !botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.addMessage[languageSelected]"
        ></span>

        <span
          v-if="!buttonDisabled && botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.modifyMessage[languageSelected]"
        ></span>

        <span
          v-if="buttonDisabled && !botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.addingMessage[languageSelected]"
        ></span>
      </vs-button>
    </div>
    <!-- </form> -->
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { FILE_FORMATS_MAP } from '@/constants/constants'
import { MediaMessageType } from '@/models/Messages/MessageTypes/MediaMessageType'
import botMessageValidationMixin from '@/mixins/botMessageValidationMixin'

export default {
  name: 'MediaMessage',
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errorMsg: [],
      buttonDisabled: false,
      hasError: false,
      FILE_FORMATS_MAP,
      showPreview: true // TODO: when editing a media msg & selects new file, 2 previews are shown
    }
  },
  mixins: [botMessageValidationMixin],
  components: {
    Message: () => import('./mediaMessage/Message'),
    MediaPreview: () => import('@/components/MediaPreview.vue'),
    BotMessageValidation: () => import('../userSays/BotMessageValidation.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'botMessageInEdit',
      'botMakerMessages',
      'botId',
      'activeChannelView'
      // 'uploadProgress'
    ]),
    savedFile() {
      const saved = Object.assign(this.botMessageInEdit.type)
      saved['mediaType'] =
        FILE_FORMATS_MAP[this.botMessageInEdit.type.fileFormat]
      return saved
    }
  },
  methods: {
    ...mapActions('botMaker', ['SAVE_BOT_MESSAGE', 'GET_DIALOGS']),
    ...mapMutations('botMaker', [
      'SET_ACTIVE_CHANNEL_VIEW',
      'RESET_BOT_MESSAGE',
      'UPDATE_MESSAGE'
    ]),
    gT(item) {
      return this.lang.botMaker.botEditor.messageType.RcsCard.errors[item][
        this.languageSelected
      ]
    },
    showError() {
      const mField = this.lang.botMaker.botEditor.missingField[
        this.languageSelected
      ]
      this.errorMsg = [`${mField} ${this.gT('picture').toLowerCase()}`]
    },
    onCancel() {
      this.errorMsg = []
    },
    async addMessage() {
      this.errorMsg = []
      this.hasError =
        this.botMessageInEdit.type.name.length === 0 ||
        (this.botMessageInEdit.type.fileUrl == undefined &&
          this.botMessageInEdit.type.fileData == undefined)

      if (this.hasError) {
        this.showError()
        return
      }

      // this.$vs.loading({ text: this.uploadProgress + '%' })
      this.botMessageInEdit.type.platforms = ['all']
      this.$vs.loading()
      // const getDialogsId =
      //   this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId

      // on update image
      if (this.botMessageInEdit.isSaved) {
        await this.botMessageInEdit.type.save(this.botId)
        this.botMessageInEdit.type.platforms = [this.activeChannelView]

        const botDialogHandler = JSON.parse(
          JSON.stringify(this.botMessageInEdit)
        )
        const botDialog = botDialogHandler.type

        this.botMessageInEdit.type = new MediaMessageType(
          (botDialog.responseFile
            ? botDialog.responseFile.name
            : botDialog.name) || botDialog.responseText,
          '',
          botDialog.responseFile
            ? botDialog.responseFile.url
            : botDialog.fileUrl,
          botDialog.responseFile
            ? botDialog.responseFile.type
            : botDialog.fileFormat,
          botDialog.platforms,
          botDialog._id,
          botDialog.responseId
        )
      }

      await this.saveValidation()

      this.SAVE_BOT_MESSAGE(this.botMessageInEdit)
        .then(async response => {
          if (this.botMessageInEdit.isSaved) {
            console.log('edit message')
            this.UPDATE_MESSAGE(this.botMessageInEdit)
            // this.RESET_BOT_MESSAGE()
            this.SET_ACTIVE_CHANNEL_VIEW('all')
            this.RESET_BOT_MESSAGE()
            // this.showPreview = true
            // return response
          } else {
            // await this.GET_DIALOGS(getDialogsId)
            // this.RESET_BOT_MESSAGE()
            this.SET_ACTIVE_CHANNEL_VIEW('all')
            this.showPreview = true
            return response
          }
        })
        .catch(error => {
          return error
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#media-message {
  .submit-button {
    text-align: right !important;
  }

  .media-message {
    display: flex;
    justify-content: center;
    .img-message {
      max-height: 150px;
      border-radius: 5px;
    }
  }
}
</style>
