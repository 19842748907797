import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'bot',
      'botMessageInEdit',
      'botValidationEnabled',
      'botMessageValidation',
      'responseValidations',
      'botMessageValidationApplyAll',
      'validationUpdateMode',
      'dialogsMap'
    ])
  },
  methods: {
    ...mapMutations('botMaker', [
      'RESET_BOT_MESSAGE_VALIDATION',
      'SET_VALIDATION_STATUS'
    ]),
    ...mapActions('botMaker', [
      'SAVE_BOT_VALIDATION',
      'UPDATE_BOT_VALIDATION',
      'SET_VALIDATION_APPLY_ALL'
    ]),
    async saveValidation() {
      if (
        this.botValidationEnabled &&
        this.botMessageValidation &&
        this.botMessageValidation.question
      ) {
        const bot = this.$route.query.bot || this.$route.query.botId
        let validationId = null
        try {
          const validation = {
            ...this.botMessageValidation,
            bot,
            version: this.bot.activeVersion
          }
          const dialog =
            this.botMessageInEdit.dialogId &&
            this.dialogsMap[this.botMessageInEdit.dialogId]
          const validationSettings = dialog
            ? dialog.sendValidationSettings
            : null
          validationId = validationSettings
            ? validationSettings.validation
            : null
          if (
            this.validationUpdateMode === 'update' &&
            this.botMessageInEdit.isSaved
          ) {
            await this.UPDATE_BOT_VALIDATION({ validationId, validation })
          } else {
            validationId = await this.SAVE_BOT_VALIDATION(validation)
          }
        } catch (error) {
          this.showValidationError()
        }

        this.botMessageInEdit.sendValidationSettings = {
          validationEnabled: true,
          validation: validationId
        }

        if (this.botMessageValidationApplyAll) {
          await this.SET_VALIDATION_APPLY_ALL({
            version: this.bot.activeVersion,
            validationId
          })
        }

        this.RESET_BOT_MESSAGE_VALIDATION()
        this.SET_VALIDATION_STATUS(false)
      } else {
        this.botMessageInEdit.sendValidationSettings = null
      }
    },
    showValidationError() {
      this.$vs.notify({
        title: this.lang.botMaker.errorTitle[this.languageSelected],
        text: this.lang.botMaker.botMessageValidation.messages.error
          .saveValidation[this.languageSelected],
        color: 'danger'
      })
    }
  }
}
